@import './assets/fonts/Inter/index.css';

* {
  scrollbar-color: #D6D6DF #F1F1F2;
  scrollbar-width: thin;
}

::-webkit-scrollbar-button {
  background-image: none;
  background-repeat: no-repeat;
  width: 5px;
  height: 0px
}

::-webkit-scrollbar-track {
  background-color: #F1F1F2;
  border-radius: 32px;
}

::-webkit-scrollbar-thumb {
  border-radius: 32px;
  background-color: #D6D6DF;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #D6D6DF;
}

::-webkit-resizer {
  background-image: none;
  background-repeat: no-repeat;
  width: 6px;
  height: 0px
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}


/* auto key passwords */

.TextFieldReg input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #efefef !important;
  -webkit-text-fill-color: #000 !important;
  color: #000 !important;
  border-width: 0 !important;
  border-image-width: 0 !important;
  border-color: rgba(0, 0, 0, 0) !important;
  border-radius: 0 !important;
}
.TextFieldReg input:hover:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #efefef !important;
}
.TextFieldReg .Mui-focused input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #efefef !important;
}

.ReactVirtualized__Grid,
.ReactVirtualized__Grid__innerScrollContainer {
  outline: none !important;
}
