@font-face {
  font-family: 'Inter';
  src: local('Inter Thin'), local('Inter-Thin'), url('Inter-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Thin Italic'), local('Inter-ThinItalic'), url('Inter-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Light'), local('Inter-Light'), url('Inter-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Light Italic'), local('Inter-LightItalic'), url('Inter-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), local('Inter-Regular'), url('Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Italic'), local('Inter-Italic'), url('Inter-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Medium'), local('Inter-Medium'), url('Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Medium Italic'), local('Inter-MediumItalic'), url('Inter-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Semi Bold'), local('Inter-SemiBold'), url('Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Semi Bold Italic'), local('Inter-SemiBoldItalic'), url('Inter-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Bold'), local('Inter-Bold'), url('Inter-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Bold Italic'), local('Inter-BoldItalic'), url('Inter-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Black'), local('Inter-Black'), url('Inter-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Black Italic'), local('Inter-BlackItalic'), url('Inter-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
